document.addEventListener('DOMContentLoaded', function () {
  let $downloads = document.querySelectorAll('.js-download');

  if ('ontouchstart' in window || 'ontouch' in window) {
    $downloads.forEach(function (item) {
      item.addEventListener('click', function (e) {
        if (!item.classList.contains('is-active')) {
          e.preventDefault();
        }
        item.classList.toggle('is-active');
      })
    });
  }
});