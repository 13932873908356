const $drodownBackdrop = document.createElement('div');
const $navbar = document.querySelector('.navbar-nav');
$drodownBackdrop.classList.add('nav-dropdown-backdrop');

window.addEventListener('DOMContentLoaded', function() {
    document.querySelector('body').appendChild($drodownBackdrop);
});

document.addEventListener('click', function (event) {
    if ( event.target.getAttribute('data-dropdown')) {
        event.preventDefault();
        toggleDropdown(event.target);
    } else {
        closeOpenDropdowns();
    }
}, false);

window.addEventListener('scroll', function (event) {
    let navbarOptions = $navbar.getBoundingClientRect();
    $drodownBackdrop.style.top = (navbarOptions.top + navbarOptions.height) + 'px';
});

function toggleDropdown(node) {
    let $dropDownEl = node.nextElementSibling;
    if (node.classList.contains('is-open')) {
        node.classList.remove('is-open');
        $dropDownEl.classList.remove('is-visible');
        hideDropdownBackdrop();
    } else {
        closeOpenDropdowns();
        node.classList.add('is-open');
        $dropDownEl.classList.add('is-visible');
        showDropdownBackdrop($dropDownEl);
    }
}

function closeOpenDropdowns() {
    let $visibleDropdowns = document.querySelectorAll('.nav-dropdown.is-visible');
    $visibleDropdowns.forEach(dropdown => {
        dropdown.classList.remove('is-visible');
        dropdown.previousElementSibling.classList.remove('is-open');
    });
    hideDropdownBackdrop();
}

function showDropdownBackdrop($dropdown) {
    $drodownBackdrop.classList.add('is-visible');
    let options = $dropdown.getBoundingClientRect();
    $drodownBackdrop.style.top = options.top + 'px';
    $drodownBackdrop.style.height = options.height + 'px';
}

function hideDropdownBackdrop() {
    $drodownBackdrop.classList.remove('is-visible');
}