document.addEventListener('DOMContentLoaded', initSlider);
const ICONS = {
  prev: '<svg xmlns="http://www.w3.org/2000/svg" class="icon">\n' +
        '    <use xlink:href="/img/icons.svg#arrow-left"></use>\n' +
        '</svg>',
  next: '<svg xmlns="http://www.w3.org/2000/svg" class="icon">\n' +
        '    <use xlink:href="/img/icons.svg#arrow-right"></use>\n' +
        '</svg>'
};
function initSlider() {
  let productImageSlider = document.querySelector('.js-product-image-slider');

  if (productImageSlider) {
    tns({
      container: '.js-product-image-slider',
      items: 1,
      slideBy: 'page',
      autoplay: true,
      mouseDrag: true,
      controlsPosition: 'bottom',
      navPosition: 'bottom',
      autoplayButtonOutput: false,
      controlsText: [ICONS.prev, ICONS.next],
      loop: true
    });
  }
}